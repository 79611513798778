$white: #ffffff;
$black: #000000;

$gray: #f2f2ed;
$gray2: #b1b1b1;
$gray3: #a8a89f;
$gray4: #d7d7ce;
$gray5: #76766a;
$gray6: #c1c1bc;


$font-family: 'Montserrat', sans-serif;
