@import "variables.scss";

* {
  margin: 0;
  padding: 0;
}


body {
  min-width: 320px;

  font-size: 14px;
  line-height: 1.4;
  color: $black;

  font-family: $font-family;

  &.overfl {
    overflow: hidden;
    height: 100vh;
  }
}

.button {
  display: inline-block;
  border: none;
  box-shadow: none;
  letter-spacing: 1px;

  &-primary {
    color: $white;
    background: $black;
    font-size: 12px;
    padding: 6px 20px;
    border: 1px solid $black;
    border-radius: 15px;

    &:hover {
      color: $black;
      background: $white;
    }
  }

  &-big {
    padding: 15px 35px;
    border: 2px solid $black;
    border-radius: 27px;
    font-size: 14px;
    letter-spacing: 1px;
  }

  &-secondary {
    background: $gray6;
    color: $white;
    font-size: 14px;
    letter-spacing: 1px;
    border: 2px solid $gray6;
    padding: 15px 35px;
    border-radius: 27px;

    &:hover {
      background: $gray5;
      color: $white;
      border-color: $gray5;
    }

    &.bg {
      border-color: $gray3;
      background: $gray3;

      &:hover {
        background: $gray5;
        color: $white;
        border-color: $gray5;
      }
    }
  }

  &-bord {
    border: 2px solid $gray4;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 15px 35px;
    border-radius: 27px;
    color: $black;

    &:hover {
      color: $white;
      background: $gray3;
      border-color: $gray3;
      text-decoration: none;
    }
  }

  &-group {
    .button {
      margin-right: 22px;
    }
  }

  &-link {
    display: inline-block;
    padding-left: 20px;

    position: relative;

    color: $black;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: underline;
    letter-spacing: 0;

    img {
      width: 14px;

      position: absolute;
      left: 0;
      top: 0;
    }

    &:hover {
      color: $black;
      text-underline: none;
    }
  }

  &-center {
    text-align: center;
  }

}

.start-page {
  padding: 30px;

  &-logo {
    display: block;
    margin-bottom: 30px;
    width: 170px;
  }

  ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px;

    li {
      padding: 5px 0;

      b {
        font-size: 20px;
      }

      a {
        font-size: 16px;
        line-height: 140%;
        color: $black;

      }
    }
  }
}

div,
span,
a,
input,
textarea,
select,
button,
.button {
  &:focus {
    outline: none;
  }
}

ul {
  li {
    list-style: none;
  }
}

a {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

a, li, img {
  -webkit-transition: all 0.2s ease;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0 0 45px 0;
  padding: 0;
}

.container {
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 500;
  padding: 20px 0;

  &.fixed {
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    top: 0 !important;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    width: 172px;
  }

  &__right {
    display: flex;
    align-items: center;

    .button {
      margin: 0 10px 0 5px;
    }
  }

  &__inst {
    display: none;

    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
  }

  &__hld {
    &-title {
      display: none;

      font-size: 20px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &-tel {
      display: none;

      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
      margin-top: 0;
    }

    &-close {
      display: none;

      position: absolute;
      right: 15px;
      top: 15px;

      img {
        width: 20px;
      }
    }
  }

  &__menu {
    margin: 0 5px;

    li {
      padding: 0 13px;
      display: inline-block;
      vertical-align: middle;

      list-style: none;

      &::marker {
        display: none;
      }

      a {
        font-size: 14px;
        text-decoration: none;
        color: $black;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-sub {
      ul {
        display: none;

        border-radius: 0;
        top: 69px;
        padding: 20px 10px 0px 25px;
        width: 504px;
        margin-left: -212px;
        //flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        height: 198px;
        position: absolute;

        background: $white;

        li {
          max-width: 40%;
          flex: 0 0 40%;
          padding: 0 7px;

          //padding-top: 5px;
          //padding-bottom: 5px;
          margin-left: -5px;
          //padding-left: 42px;
          //margin-right: 15px;

          &:nth-of-type(2n) {
            max-width: 60%;
            flex: 0 0 60%;
          }

          a {
            display: block;
            padding: 12px 5px 12px 38px;

            position: relative;

            img {
              width: 30px;
              height: 30px;

              position: absolute;
              top: 0;
              bottom: 0;
              left: 3px;
              margin: auto;

              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }

      &:hover {
        margin-top: -10px;
        padding-top: 10px;
        background: $white;
        padding-bottom: 16px;
        margin-bottom: -16px;

        & > a {
          text-decoration: none!important;
        }

        ul {
          display: flex;

          li {
            a {
              &:hover {
                background: $gray;
                border-radius: 14px;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &__phone {
    display: block;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: $black!important;
    margin: 0 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__toggler {
    display: none;
    padding: 0 16px;
  }
}

.banner {
  position: relative;
  padding: 230px 0 155px 0;
  background-size: cover;
  background-color: $gray;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &__inner {

  }

  &__title {
    font-size: 60px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__tx {
    font-size: 38px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.services {
  padding: 80px 0;

  &__inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__hld {
    max-width: 20%;
    flex: 0 0 20%;
    padding: 0 15px;
  }

  &__item {
    padding: 35px 20px;
    height: 327px;
    border-radius: 5px;
    overflow: hidden;

    position: relative;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: -webkit-linear-gradient(#fff, rgba(255, 255, 255, 0));
      background: -o-linear-gradient(#fff, rgba(255, 255, 255, 0));
      background: linear-gradient(#fff, rgba(255, 255, 255, 0));
      opacity: .5;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      z-index: 1;
    }

    &:hover {
      &:after {
        opacity: 1;
        background: rgba(0, 0, 0, 0.35);
      }

      .services__list {
        opacity: 1;
      }

      .services__title {
        color: $white;
      }
    }
  }

  &__title {
    color: $black;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    text-decoration: none;
    position: relative;
    z-index: 4;
  }

  &__link {
    display: none;
  }

  &__list {
    width: 100%;
    height: 100%;
    //padding: 55px 20px;

    position: relative;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;


    ul {
      li {
        margin-top: 10px;
        line-height: 1;

        a {
          width: 100%;
          display: inline-block;

          font-size: 12px;
          color: $white;
          text-decoration: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.about {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 50%;
    margin-right: -30px;
    bottom: 0;
    background: $gray;
    z-index: -1;
  }

  &__text {
    padding: 80px 0;
  }

  &__doctor {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;

    &-img {
      margin-right: 30px;
    }

    &-tx {
      font-size: 14px;
      line-height: 24px;
      color: $black;

      &:first-letter {
        font-weight: 700;
      }
    }
  }

  &__descr {
    display: flex;
    align-items: flex-start;
    margin: 0 -15px 40px;

    &-item {
      width: 50%;
      padding-right: 15px;
      padding-left: 15px;
    }

    &-title {
      text-transform: uppercase;
      font-size: 12px;
      color: $gray2;
      margin-bottom: 5px;
    }

    &-tx {
      color: $black;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__link {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding-right: 20px;
    background: url(../images/svg/arrow.svg) 100% 50% no-repeat;
    background-size: 12px 12px;
    color: $black;

    &:hover {
      text-decoration: underline;
      color: $black;
    }
  }

  &__info {
    max-width: 405px;
    margin: 0 auto;
    padding: 40px 0;

    &-item {
      margin-bottom: 20px;
    }

    &-title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      line-height: 1.3;
      margin: 0 0 15px 0;
      color: $gray2;
      padding: 0;
    }

    &-video {

    }

    &-img {
      margin: 0 -10px;

      a {
        display: none;
        height: 162px;
        margin-bottom: 30px;
        padding: 0 10px;

        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.2));

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: inline-block;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  &-page {
    position: relative;
    padding: 90px 0 0;
    min-height: 720px;

    background: $gray;

    &__inner {
      display: flex;
      //align-items: flex-end;
    }

    &__img {

    }

    &__text {
      margin-left: 60px;

      h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 1.3;
        margin: 0 0 10px 0;
        padding: 0;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 45px;
        color: $black;
      }
    }

    &__info {
      h2 {
        margin-bottom: 0;

        font-size: 28px;
        line-height: 40px;
      }

      &-item {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 45px;
        color: $black;

        p {
          margin-bottom: 18px;
          //margin-top: 25px;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: 700;
        color: $black;

        span {
          margin: 0 0 0 5px;
          font-size: 12px;
          color: $gray2;
          font-weight: 400;
        }
      }
    }

    &__rating {
      width: 180px;

      &-numb {
        display: block;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 5px;
        color: $black;
      }

      &-link {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        color: $gray2!important;
        text-decoration: none;
        padding-bottom: 17px;
        background: url(../images/bg-prodoctor.png) 0 100% no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__holder {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &-doctor {
    padding: 80px 0;

    h2 {
      font-size: 30px;
    }

    &:after {
      display: none;
    }

    .about__text,
    .about__info {
      padding: 0;
    }

    &__list {
      max-width: 360px;
      margin-bottom: 30px;

      &-item {
        margin-bottom: 25px;

        p {
          margin: 0;
        }
      }

      &-year {
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
      }

      &-name {
        font-size: 12px;
        color: $gray2;
        margin-bottom: 5px;
      }
    }

    &__descr {
      margin-bottom: 30px;

      &-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.3;
        margin: 0 0 20px 0;
        padding: 0;
      }

      ul {
        margin-left: 20px;

        li {
          margin-top: 15px;
          position: relative;
          list-style: disc;
          color: $black;
        }
      }
    }

    &__im {
      &-inner {
        display: flex;
        align-items: flex-start;
      }

      &-img {
        display: block;
        width: 115px;
        margin-right: 25px;
      }
    }
  }
}

.results {
  padding: 80px 0;

  &__item {
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }

  &__img {
    height: 200px;
    margin-bottom: 20px;
    position: relative;

    &-before {
      position: absolute;
      left: 0;
      top: 20px;
      bottom: 20px;
      width: 148px;
      border-radius: 10px;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-after {
      position: absolute;
      left: 55px;
      top: 0;
      bottom: 0;
      width: 166px;
      border-radius: 10px;
      box-shadow: -22px 22px 38px rgba(130, 115, 81, 0.33);
      z-index: 2;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__title {
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 16px;
  }

  &__link {
    display: inline-block;
    padding-right: 15px;
    background: url(../images/svg/arrow.svg) 100% 50% no-repeat;
    background-size: 8px 8px;
  }

  &-bl {
    position: relative;
    padding: 90px 0 60px 0;
    background: $gray;
    overflow: hidden;

    h1 {
      margin-bottom: 45px;
      font-size: 40px;
      font-weight: 700;
    }

    &__menu {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;

      position: absolute;


      h2 {
        padding: 0;
        margin: 0 0 20px 0;
        font-weight: 700;
        color: $black;
        font-size: 28px;
        line-height: 1.2;
      }

      ul {
        li {
          display: inline-block;
          margin-bottom: 10px;
          margin-right: 20px;

          a {
            height: 50px;
            display: flex;
            align-items: center;
            padding: 10px 20px 10px 60px;

            position: relative;

            text-decoration: none;
            border-radius: 15px;
            color: $black!important;

            img {
              width: 30px;
              height: 30px;

              position: absolute;
              left: 20px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            &:hover {
              text-decoration: underline;
            }
          }

          &.active {
            a {
              background: $white;
            }
          }
        }
      }
    }

    &__foto,
    &__all {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;

      margin-left: auto!important;

      .price {
        padding-top: 0;
      }

      &-item {
        display: block;
        margin-bottom: 55px;
      }
    }

    &__row {
      //justify-content: flex-end;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      position: relative;
    }
  }
}

.airSticky_absolute { display: none !important; }

.reviews {
  padding: 80px 0;

  background: $gray;

  &__item {
    padding: 0 15px;
    outline: none;
  }

  &__body {
    border-radius: 10px;
    background: $white;
    position: relative;
    padding: 40px 50px;
    margin-left: 39px;

    &:after {
      content: '';
      position: absolute;
      left: -39px;
      top: 27px;
      width: 47px;
      height: 47px;
      background: url(../images/svg/quote.svg) 0 0 no-repeat;
      -webkit-background-size: 47px 47px;
      background-size: 47px 47px;
      z-index: 2;
    }
  }

  &__top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {

    }
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
  }

  &__serv {
    font-size: 12px;
    color: $gray2;
  }

  &__rating {
    margin: 0 -3px;
    display: flex;
    align-items: center;

    &-item {
      padding: 0 3px;

      img {
        width: 15px;
      }
    }
  }

  &__text {
    min-height: 116px;
    height: 116px;
    font-size: 14px;
    line-height: 1.7;
    overflow: hidden;
    position: relative;

    &.active {
      height: auto;
      min-height: inherit;

      .reviews__more {
        display: none;
      }
    }
  }

  &__more {
    display: inline-block;

    position: absolute;
    right: 0;
    bottom: -3px;
    text-decoration: none;
    font-size: 12px;
    color: $gray3!important;
    padding: 5px 20px 5px 10px;
    z-index: 2;
    background: #fff url(../images/svg/arrow-gray.svg) 100% 50% no-repeat;
    -webkit-background-size: 12px 12px;
    background-size: 12px 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__slider {
    margin-bottom: 35px;

    .slick {
      &-arrow {
        position: absolute;
        right: 15px;
        top: -42px;
        padding: 0;
        outline: none;
        border: none;
        width: 20px;
        height: 20px;
        text-indent: -250px;
        overflow: hidden;
        cursor: pointer;

        background: url(../images/svg/arrow.svg) 0 0 no-repeat;
        -webkit-background-size: 20px 20px;
        background-size: 20px 20px;
      }

      &-prev {
        transform: rotate(180deg);
        right: 50px;
      }

      &-disabled {
        opacity: 0.3;
        cursor: default;
      }

      &-dots {
        list-style: none;
        margin: 15px 0 0 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
        padding: 0;

        li {
          margin: 5px;

          width: 8px;
          height: 8px;
          outline: none;
          padding: 0;
          overflow: hidden;
          border: none;
          background: $gray2;
          border-radius: 50%;

          button {
            display: none;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 15px 0 50px;

    &-link {
      display: inline-block;
      text-decoration: none;
      position: relative;
      padding-bottom: 25px;
      background: url(../images/bg-prodoctor.png) 0 100% no-repeat;
      -webkit-background-size: 100% auto;
      background-size: 100% auto;
      color: $black!important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__img {
    margin: -7px 0 13px -7px;

    a {
      margin-right: 14px;
      margin-bottom: 7px;

      display: inline-block;
      vertical-align: top;
      width: 84px;
      height: 84px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;

      img {
        max-width: 100%;

        border-radius: 3px;
      }
    }
  }

  &-tw {
    padding: 80px 0;

    background: $gray;

    &__title {
      margin-bottom: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 0;
      }

      &-bl {
        .button, .reviews__bottom-link {
          vertical-align: middle;
          display: inline-block;
        }

        .reviews__bottom-link {
          margin-right: 30px;
        }
      }
    }

    .reviews__item {
      margin: 0 0 30px;
      padding: 0;
    }
  }
}

.subscription {
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  background: $gray;

  &__title {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
    color: $black;
  }

  &__text {
    p {
      color: $black;
      line-height: 24px;
    }
  }

  &__bottom {
    .button {
      margin-right: 10px;
    }
  }

  &__img {
    display: block;

    position: absolute;
    left: -20%;
    margin-top: -25%;
  }
}

.footer {
  padding: 35px 0 45px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    width: 202px;
    margin-bottom: 50px;

    img {
      width: 100%;
    }
  }

  &__info {
    &-links {
      display: block;
      font-size: 12px;

      margin-top: 17px;
      line-height: 15px;
      color: $black;

      &:hover {
        text-decoration: underline;
        color: $black;
      }
    }
  }

  &__menu {
    margin: 0 0 15px 0;

    .button-link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-title {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 700;
      color: $black;
    }

    ul {
      li {
        margin-top: 10px;

        a {
          display: block;

          font-size: 12px;
          color: $black;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__tel {
    display: block;

    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $black;
      text-decoration: underline;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    &-copyright {
      color: $gray2;
      font-size: 10px;
      line-height: 16px;
    }

    &-dev {
      color: $gray2;
      font-size: 10px;
      line-height: 16px;

      a {
        color: $black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.breadcrumb {
  padding: 0;

  position: relative;
  z-index: 3;

  &-block {
    //padding: 42px 0 40px;
    margin: 0 0 25px 0;

    .breadcrumb {
      padding: 0;
      margin: 0;

      background: transparent;

      &-item {
        font-size: 11px;
        line-height: 170%;
        color: $black;
        margin-bottom: 0;

        &:first-of-type {
          &:before {
            display: none!important;
          }
        }

        a {
          font-size: 11px;
          line-height: 170%;
          color: $black;

          .https404 & {
            color: $white;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        & + .breadcrumb-item {
          &:before {
            content: '>';
            padding-right: 3px;
            padding-left: 3px;

            font-size: 11px;
            line-height: 170%;
            color: $black;
          }
        }
      }
    }
  }
}

.catalog {
  position: relative;
  padding: 90px 0 60px 0;
  background: $gray;
  overflow: hidden;

  h1 {
    font-weight: 700;
    margin-bottom: 41px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__item {
    max-width: 20%;
    flex: 0 0 20%;
    padding: 0 15px;

    ul {
      margin-bottom: 0;

      li {
        margin-top: 10px;

        a {
          color: $black;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__img {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    height: 200px;
    width: 200px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 10px;
    -webkit-transition: all 0.2s ease;
  }

  &__title {
    margin-bottom: 15px;

    display: block;
    font-size: 18px;
    font-weight: 700;
  }

  &-tl {
    padding-top: 30px;

    &__inner {
      display: flex;
      align-items: center;
    }

    &__img {
      //position: absolute;
      bottom: 0;
      right: 50%;
      width: 540px;
    }

    &__text {
      padding-left: 70px;

      ul {
        margin-bottom: 0;

        li {
          margin-bottom: 15px;
          position: relative;
          color: $black;
          list-style: disc;
          line-height: 24px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.price {
  position: relative;
  padding: 90px 0 60px 0;
  background: $gray;
  overflow: hidden;

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 0 45px 0;
    padding: 0;
  }

  &__catalog {
    margin-bottom: 20px;
    margin-top: -20px;

    &-item {
      margin-bottom: 10px;
      margin-right: 20px;
      display: inline-block;
      padding: 7px 25px;
      background: $white;
      font-size: 14px;
      color: $black!important;
      font-weight: 700;
      text-decoration: none;
      border-radius: 17px;

      &.active {
        background: $gray4;
      }

      &:hover {
        background: $gray4;
      }
    }
  }

  &__img {
    margin-bottom: 45px;
    position: relative;
    padding: 50px 55px;
    overflow: hidden;
    border-radius: 5px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: -webkit-linear-gradient(left, #d7d7d7, rgba(215, 215, 215, 0));
      background: -o-linear-gradient(left, #d7d7d7, rgba(215, 215, 215, 0));
      background: linear-gradient(to right, #d7d7d7, rgba(215, 215, 215, 0));
      z-index: 1;
    }

    h2 {
      margin-bottom: 0;
      color: $white;

      position: relative;
      z-index: 3;
    }
  }

  &__table {
    table {
      width: 100%;

      thead {
        tr {
          th {
            border-bottom: 2px solid $black;
            padding: 5px;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      tbody {
        tr {
          th {
            font-size: 18px;
            font-weight: 700;
            padding: 15px 5px 8px 5px;
          }

          td {
            padding: 8px 5px;
            font-size: 16px;

            span {
              font-weight: 700;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.contacts {
  position: relative;
  padding: 90px 0 0;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 50%;
    margin-right: -15px;
    bottom: 0;
    background: $gray;
    z-index: 1;
  }

  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 45px;
  }

  &__inner {
  }

  &__img {
    display: flex;
    margin-top: -10%;
    align-items: flex-end;

    position: relative;
    z-index: 1;

    img {
      margin-left: 50px;
    }
  }

  &__text {
    max-width: 350px;
    position: relative;
    z-index: 2;

    p {
      margin-bottom: 15px;
    }

    &-tel {
      display: inline-block;
      margin-bottom: 30px;

      font-size: 16px;
      font-weight: 700;
      color: $black;

      &:hover {
        text-decoration: underline;
      }
    }

    &-inst {
      margin-bottom: 30px;

      &-title {
        font-size: 14px;
        margin-bottom: 7px;
        color: $black;
      }

      .button {
        text-decoration: none;
        margin-top: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-address {
    padding: 80px 0;

    h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.3;
      margin: 0 0 20px 0;
    }

    &__inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__item {
      width: 33.333%;
      padding-right: 40px;

      p {
        padding-left: 10px;
        color: $black;
      }
    }

    &__link {
      padding-left: 10px;
      font-weight: 700;
      display: inline-block;
      text-decoration: none;
      color: $black!important;

      &:hover {
        text-decoration: underline;
      }
    }

    &__icon {
      margin-bottom: 10px;

      &-item {
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        width: 61px;
        height: 61px;
        background: $gray;
        border-radius: 50%;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 15px;
      color: $black;
    }
  }
}

.map {
  width: 100%;
  height: 400px;

  position: relative;

  #map {
    width: 100%;
    height: 100%;
  }
}

.policy {
  padding: 120px 0 60px;

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 0 45px 0;
  }

  &__inner {
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
      color: $black;
    }
  }
}

.license {
  padding: 120px 0 60px;

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 0 45px 0;
  }

  &__inner {
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
      color: $black;
    }
  }

  &__foto {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px 0;

    text-align: center;

    a {
      display: inline-block;
      flex: 0 0 33.333%;
      padding: 0 15px;
      margin-top: 30px;
    }
  }
}

.https404 {
  padding: 120px 0 60px;

  &__inner {
    text-align: center;

    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 35px;
      color: $black;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 200px;
    line-height: 200px;
  }

  &__nm {
    margin-bottom: 20px;

    font-size: 40px;
    line-height: 40px;
  }
}

.reception {
  height: 100vh;
  min-height: 750px;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  &__logo {
    display: inline-block;
    width: 172px;
    padding: 20px 0;

    img {
      width: 172px;
    }
  }

  &__inner {
    width: 66.666%;
    padding: 50px 0 55px 0;

    h1 {
      line-height: 1.2;
      margin-bottom: 63px;
      font-weight: 700;
      color: $black;
      font-size: 60px;
    }
  }

  &__list {
    width: 95%;
    margin: 0 -15px 70px;
    display: flex;
    flex-wrap: wrap;

    &-holder {
      padding: 0 15px;
      margin-bottom: 28px;

      flex: 0 0 50%;
      max-width: 50%;
    }

    &-item {
      display: block;
      height: 100%;

      font-weight: 700;

      color: $white;
      background: $black;
      font-size: 15px;
      padding: 11px 27px;
      border-radius: 27px;
      overflow: hidden;
      border: 2px solid transparent;

      span {
        display: block;
        font-weight: normal;
        margin-top: 8px;
        font-size: 10px;
        max-width: 100%;
        line-height: 17px;
      }

      &:hover {
        color: $black;
        background: $white;
        border-color: $black;
      }
    }
  }

  &__bottom {
    .button {
      margin-right: 30px;
      text-decoration: none;
      font-size: 14px;
      letter-spacing: 0.01em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.operation {
  position: relative;
  padding: 90px 0 60px 0;
  background: $gray;
  overflow: hidden;


  &__inner {
    display: flex;
    align-items: center;
    min-height: 550px;
  }

  &__text {
    max-width: 40%;
    flex: 0 0 40%;

    h1 {
      margin-bottom: 10px;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.3;
    }

    p {
      margin-bottom: 45px;

      font-size: 14px;
    }
  }

  &__img {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    bottom: 0;
    width: 752px;
    height: 595px;
    background-position: center bottom !important;
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      padding: 0 15px 0 40px;
      margin-bottom: 35px;
      max-width: 50%;
      flex: 0 0 50%;

      position: relative;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  &-slider {

    &__inner {
      margin: 0 -15px 30px;

      .slick {
        &-arrow {
          position: absolute;
          right: 15px;
          top: -42px;
          padding: 0;
          outline: none;
          border: none;
          width: 20px;
          height: 20px;
          text-indent: -250px;
          overflow: hidden;
          cursor: pointer;

          background: url(../images/svg/arrow.svg) 0 0 no-repeat;
          -webkit-background-size: 20px 20px;
          background-size: 20px 20px;
        }

        &-prev {
          transform: rotate(180deg);
          right: 50px;
        }

        &-disabled {
          opacity: 0.3;
          cursor: default;
        }

        &-dots {
          list-style: none;
          margin: 15px 0 0 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important;
          padding: 0;

          li {
            margin: 5px;

            width: 8px;
            height: 8px;
            outline: none;
            padding: 0;
            overflow: hidden;
            border: none;
            background: $gray2;
            border-radius: 50%;

            button {
              display: none;
            }
          }
        }
      }
    }

    &__item {
      padding: 0 15px;
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      img {
        border-radius: 10px;
      }

      span {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        font-weight: bold;
        padding: 10px 15px 0 15px;
        text-align: center;
        font-size: 18px;
        color: $black;
      }
    }
  }

  &-holder {
    padding: 80px 0;

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.3;
      margin: 0 0 20px 0;
    }
  }

  &-text {
    margin-bottom: 30px;

    position: relative;

    &__inner {
      display: flex;
      justify-content: space-between;

      position: relative;
    }

    &__tx {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;

      p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $black;
      }

      ul {
        padding-left: 40px;

        &.nn {
          padding-left: 0;

          li {
            list-style: none;

            &:before  {
              content: '';
              display: inline-block;
              width: 6px;
              height: 1px;

              position: absolute;
              left: 0;
              top: 12px;

              background: $black;
            }
          }
        }

        li {
          padding-left: 20px;

          position: relative;

          content: $black;
          font-size: 14px;
          line-height: 24px;

          &:before  {
            content: '';
            width: 6px;
            height: 6px;
            display: inline-block;

            position: absolute;
            left: 0;
            top: 8px;

            border-radius: 10px;
            background: $black;
          }
        }
      }

      ol {
        padding-left: 50px;

        li {

          position: relative;

          content: $black;
          font-size: 14px;
          line-height: 24px;
        }
      }

      h3 {
        font-size: 14px;
        line-height: 24px;
        color: $black;
        font-weight: 700;
      }
    }

    &__pl {

      &__inner {
        padding: 30px 20px 30px 0;

        position: absolute;
        top: 0;
        right: 0!important;
      }
    }

    &__consultation {
      padding-right: 30px;

      position: relative;
      top: 30px;

      &:after {
        content: '';
        position: absolute;
        top: -30px;
        right: 0;
        background: $gray;
        height: 218px;
        width: 247px;
        z-index: 1;
      }

      &-title {
        margin-bottom: 25px;
        font-size: 27px;
        line-height: 1;
        color: $black;
        font-weight: 700;

        position: relative;
        z-index: 3;
      }

      .button {
        position: relative;
        z-index: 3;
      }
    }
  }

  &-price {
    &__title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      &-img {
        width: 61px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        background: $gray;
        border-radius: 50%;

        img {
          width: 31px;
          height: 31px;
        }
      }
    }

    &__nm {
      font-size: 28px;
      font-weight: 700;
      color: $black;
      line-height: 1.3;
    }
  }
}

.button-bt {
  display: inline-block;

  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10001;

  text-decoration: none;
  font-family: sans-serif;
  background: rgb(34, 193, 94);
  color: rgb(255, 255, 255)!important;
  line-height: 38px;
  border-radius: 36px;
  padding-left: 28px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 20px -5px;

  padding: 0px 18px;
  padding-left: 37px;

  img {
    width: 14px;
    height: 14px;

    position: absolute;
    left: 18px;
    top: 12px;
    margin: auto;

    margin-right: 7px;
    margin-bottom: 2px;
  }


}

.modal {
  &-dialog {
    width: 230px;
    transform: none;

    position: absolute;
    bottom: 40px;
    right: 20px;
    top: initial;

    background: transparent;
    border: none;
    box-shadow: none;
  }

  &-content {
    background: transparent;
    border: none;
    box-shadow: none;


    .close {
      width: 24px;
      height: 24px;
      opacity: .5;

      position: absolute;
      right: 0;
      top: -30px;

      background: rgb(34, 192, 94);
      color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 500;
      border-radius: 24px;
      font-size: 21px;

      &:hover {
        opacity: 1;
      }

      img {
        width: 10px;
      }
    }

    &__top {
      background: rgb(51, 51, 51);
      color: rgb(255, 255, 255);
      text-align: center;
      padding: 15px 30px 10px;
      font-size: 12px;
      line-height: 1.4;
      border-radius: 4px 4px 0px 0px;

      img {
        display: inline-block;
        margin-bottom: 10px;
        max-width: 48px;
      }

      p {
        font-size: 12px;
        color: $white;
      }
    }

    &__bottom {
      background: rgb(255, 255, 255);
      color: rgb(136, 143, 187);
      text-align: center;
      padding: 10px 30px;
      font-size: 12px;
      line-height: 1.4;
      border-radius: 0px 0px 4px 4px;
      width: 230px;
      margin: 0px 0px 20px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 20px -5px;
    }

    &__form {
      background: rgb(255, 255, 255);
      color: rgb(136, 143, 187);
      text-align: center;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 1.4;
      border-radius: 4px;
      width: 230px;
      margin: 0px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 20px -5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        height: 36px;
        font-size: 12px;
        color: $black;
        border: none;
      }

      svg {
        width: 25px;
        height: 25px;
      }

      &-btn {
        display: block;
      }
    }
  }
}

.particle {
  &-wrapper {
    position: relative;
    overflow: hidden;

    & >.container {
      position: relative;
      z-index: 2;
    }
  }

  &-network-animation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}





@media screen and(max-width: 1199px) {
  h2 {
    font-size: 36px;
  }


  .header {
    &__menu {
      li {
        padding: 0 6px;
      }
    }
  }

  .banner {
    padding: 160px 0 100px 0;

    &__title {
      font-size: 50px;
    }

    &__tx {
      font-size: 32px;
    }
  }

  .services {
    padding: 70px 0;

    &__inner {
      justify-content: center;
    }

    &__hld {
      max-width: 33.333%;
      flex: 0 0 33.333%;
    }

    &__item {
      margin-bottom: 30px;
    }
  }

  .about {
    &__text {
      padding: 70px 0;
    }

    &__info {
      max-width: 300px;

      &-img {
        a {
          height: 175px;
          padding: 0 15px;

          &:nth-of-type(3) {
            display: none;
          }
        }
      }
    }

    &-page {
      &__inner {
        align-items: flex-end;
      }

      &__img {
        width: 500px;
        flex: 0 0 500px;

        img {
          max-width: 100%;
          margin-left: -50px;
        }
      }

      &__text {
        margin-left: -20px;

        h1 {
          font-size: 36px;
        }
      }

      &__info {
        &-item {
          padding-right: 30px;
        }
      }
    }

    &-doctor {
      padding: 70px 0;
    }
  }

  .results {
    padding: 70px 0;

    &-bl {
      h1 {
        font-size: 36px;
      }

      &__menu {
        h2 {
          font-size: 24px;
        }
      }
    }
  }

  .reviews {
    padding: 70px 0;

    &-tw {
      padding: 70px 0;
    }

    &__body {
      padding: 35px 40px;
    }
  }

  .subscription {

  }

  .catalog {
    h1 {
      font-size: 36px;
    }

    &__img {
      width: 162px;
      height: 162px;
    }

    &__item {
      ul {
        li {
          margin-top: 7px;

          a {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    &-tl {
      &__img {
        img {
          width: 510px;
        }
      }

      &__text {
        padding-left: 30px;
      }
    }
  }

  .price {
    h1 {
      font-size: 36px;
      margin-bottom: 25px;
    }

    &__catalog {
      margin-top: 0;
    }

    &__img {
      margin-bottom: 35px;

      h2 {
        font-size: 30px;
      }
    }
  }

  .contacts {
    h1 {
      font-size: 36px;
    }

    &-address {
      padding: 70px 0;
    }
  }

  .policy {
    h1 {
      font-size: 36px;
    }
  }

  .license {
    h1 {
      font-size: 36px;
    }
  }

  .reception {
    &__inner {
      h1 {
        font-size: 50px;
      }
    }
  }

  .operation {
    &__text {
      h1 {
        font-size: 36px;
      }
    }

    &__inner {
      min-height: 430px;
    }

    &__img {
      width: 630px;
      height: 498px;
      margin-left: -45px;
    }
  }

  .operation {
    padding: 70px 0;
  }
}

@media screen and(max-width: 991px) {
  h2 {
  font-size: 30px;
  margin-bottom: 30px;
  }

  .button {
    &-primary {
      padding: 10px 23px;
      border-radius: 19px;
    }

    &-big,
    &-secondary,
    &-bord {
      padding: 10px 23px;
      border-radius: 19px;
    }
  }

  .header {
  padding: 15px 0;

  &__hld {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 35px 30px 150px;
    background: #f2f2ed;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }

    &-title,
    &-tel,
    &-close {
      display: block;

      color: $black!important;
    }
  }

  &__inst {
    display: block;
    padding-left: 18px;
    margin-top: 20px;
    margin-bottom: 15px;

    position: relative;

    color: $black!important;

    img {
      width: 12px;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__toggler {
    display: block;

    img {
      width: 21px;
    }
  }

  &__menu {
    display: block;
    margin: 0 0 55px 0;

    &-sub {
      margin-bottom: 0!important;
    }

    li {
      padding: 0!important;
      margin-top: 0!important;

      display: block;
      margin-bottom: 15px!important;

      &:last-of-type {
        margin-bottom: 10px!important;
      }

      &:hover {
        background: transparent;
      }

      ul {
        display: block!important;
        width: auto;
        height: auto;
        margin-left: 0;
        padding: 17px 10px 4px 10px;

        position: relative;
        top: 0;

        background: transparent;

        li {
          max-width: 100%!important;
          flex: 0 0 100%!important;
          margin: 0 0 25px!important;

          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            padding: 0;

            img {
              display: none;
            }
          }
        }
      }
    }
  }
  }

  .banner {
  padding: 125px 0 75px 0;

  &__title {
    font-size: 36px;
  }

  &__tx {
    font-size: 24px;
  }
  }

  .about {
    background: $gray;

    &:after {
      display: none;
    }

    &__info {
      padding-top: 0;
      padding-bottom: 70px;
      max-width: 100%;

      &-item {
        margin-bottom: 0;
      }

      &-img {
        margin: 0 -15px;

        a {
          height: 212px;
        }
      }
    }

    &__text {
      padding-bottom: 20px;
    }

    &-page {
      &__img {
        width: 400px;
        flex: 0 0 400px;

        img {
          margin-left: -150px;
        }
      }

      &__text {
        margin-left: -150px;
      }
    }

    &-doctor {
      background: $white;

      .about__text {
        margin-bottom: 50px;
      }
    }
  }

  .results {
    padding-bottom: 40px;

    &__item {
      width: 221px;
      margin: 0 auto 30px;
    }

    &-bl {
      h1 {
        font-size: 30px;
      }

      &__menu {
        ul {
          li {
            font-size: 12px;
          }
        }
      }

      &__foto {
        &-item {
          margin-bottom: 35px;
        }
      }
    }
  }

  .reviews {
    &__slider {
      margin-top: -20px;
    }
  }

  .subscription {
    padding: 45px 0;

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__img {
      width: 600px;

      margin-top: -20%;

      img {
        max-width: 100%;
      }
    }
  }

  .footer {
    &__inner {
      display: block;
    }

    &__menu {
      padding-top: 13px;
      width: 40%;
      vertical-align: top;
      display: inline-block;

      &-mob {
        display: none;
      }
    }

    &__consult {
      padding-top: 13px;
      width: 40%;
      vertical-align: top;
      display: inline-block;
    }

    &__bottom {
      margin-top: 30px;
    }

    &__logo {
      margin-bottom: 15px;
    }
  }

  .catalog {
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__row {
      flex-wrap: wrap;
    }

    &__item {
      width: 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%;
      margin-bottom: 30px;
    }

    &__img {
      width: 200px;
      height: 200px;
    }

    &-tl {
      &__img {
        img {
          width: 470px;
          margin-left: -150px;
        }
      }

      &__text {
        padding-left: 0;
      }
    }
  }

  .price {
    h1 {
      font-size: 30px;
      margin-bottom: 15px;
    }

    &__img {
      padding: 45px 50px;
      margin-bottom: 35px;

      h2 {
        font-size: 26px;
      }
    }
  }

  .contacts {
    &:after {
      margin-right: -120px;
      margin-left: 10px;
    }
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__text {
      padding-bottom: 35px;
    }

    &__img {
      img {
        margin-left: 130px;
        transform: scale(1.5);
      }
    }

    &-address {
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }

      &__inner {
        flex-wrap: wrap;
      }

      &__item {
        margin-bottom: 40px;
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }

  .policy {
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .license {
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .reception {
    height: auto;
    min-height: inherit;

    &__inner {
      h1 {
        font-size: 36px;
      }
    }

    &__list {
      display: block;

      &-holder {
        margin-bottom: 10px;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .operation {
    &-text {
      &__tx {
        padding-right: 50px;
      }

      &__consultation {
        top: 0;
        padding-right: 0;

        &:after {
          width: 230px;
          height: 388px;
        }
      }
    }

    &__text {
      h1 {
        font-size: 30px;
      }
    }

    &__inner {
      min-height: 370px;
    }

    &__img {
      width: 530px;
      height: 419px;
      margin-left: -45px;
    }

    &__list {
      display: block;

      &-item {
        flex: 0 0 100%;
        max-width: 100%;

        br {
          display: none;
        }
      }
    }
  }
}

@media screen and(max-width: 767px) {
  .button {
    &-group {
      .button {
        margin-bottom: 11px;
      }
    }
  }

  h2 {
  font-size: 24px;
  margin-bottom: 15px;
  }

  .header {
  &__right {
    .button {
      display: none;
    }
  }
  }

  .banner {
  padding-bottom: 35px;

  &__title {
    font-size: 28px;
    line-height: 1.1;
  }

  &__tx {
    font-size: 16px;
    margin-bottom: 25px;
  }
  }

  .services {
  padding: 35px 0;

  &__inner {
    margin: 0 -10px;
  }

  &__hld {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 10px;
  }

  &__item {
    height: 124px;
    padding: 0;
    margin-bottom: 20px;

    &:hover {
      &:after {
        background: transparent;
      }
    }
  }

  &__link {
    display: block;
    padding: 50px 20px;

    position: relative;
    z-index: 3;

    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    color: $black;
  }

  &__title {
    display: none;
  }

  &__list {
    display: none;
  }
  }

  .about {
    &__text {
      padding: 35px 0 20px;
    }

    &__info {
      padding-bottom: 30px;
    }

    &-page {
      &__inner {
        flex-wrap: wrap;
      }

      &__text {
        order: 1;
        margin-left: 0;

        h1 {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }

      &__info {
        margin-bottom: -30px;

        h2 {
          margin-bottom: 5px;
          font-size: 20px;
        }
      }

      &__img {
        order: 2;
        width: 330px;
        flex: 0 0 330px;
        margin: auto;

        img {
          display: block;
          margin-left: 0;
        }
      }
    }

    &-doctor {
      padding: 35px 0;

      h2 {
        font-size: 22px;
        margin-bottom: 30px;
      }

      &__descr {
        &-title {
          font-size: 20px;
        }
      }
    }
  }

  .breadcrumb-block {
    display: none;
  }

  .results {
    padding: 35px 0 5px;

    &-bl {
      h1 {
        font-size: 24px;
        margin-bottom: 15px;
      }

      &__menu {
        flex: 0 0 100%;
        max-width: 100%;

        position: relative;

        h2 {
          display: none;
        }

        ul {
          li {
            margin-right: 10px;
            display: inline-block;

            a {
              min-height: 30px;
              height: auto;
              padding: 5px 15px 5px 50px;

              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      &__foto,
      &__all {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &__row {
        display: block;
      }
    }
  }

  .reviews {
    padding: 35px 0;

    &-tw {
      padding: 35px 0;
    }

    &__slider {
      margin-top: 0;
    }

    &__bottom {
      &-link {
        width: 160px;
      }
    }
  }

  .subscription {
    padding: 45px 0 0px 0;
    text-align: center;

    &__title {
      font-size: 20px;
      margin-bottom: 10px;

      br {
        display: none;
      }
    }

    &__text {
      p {
        line-height: 1.6;
        margin-bottom: 15px;

        br {
          display: none;
        }
      }
    }

    &__img {
      width: auto;
      margin-top: 40px;
      position: relative;
      left: 0;
    }
  }

  .footer {
    text-align: center;

    &__logo {
      margin: auto;
    }

    &__info {
      margin-bottom: 30px;

      .button-link {
        margin-top: 10px;
      }

      &-links {
        br {
          display: none;
        }
      }
    }

    &__menu {
      display: block;
      width: 100%;

      &-mob {
        display: none;
      }

      ul {
        display: none;
      }
    }

    &__consult {
      width: 100%;

      .footer__menu {
        display: none;
      }
    }
  }

  .catalog {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__item {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-tl {
      &__inner {
        flex-wrap: wrap;
      }

      &__img {
        order: 2;

        text-align: center;

        img {
          width: 270px;
          margin-left: 0;
        }
      }

      &__text {
        width: 100%;
        flex: 0 0 100%;
        order: 1;
        padding-left: 20px;
        margin-bottom: 30px;
      }
    }
  }

  .price {
    h1 {
      font-size: 24px;
    }

    &__catalog {
      &-item {
        font-size: 12px;
        padding: 5px 15px;
        margin-right: 10px;
      }
    }

    &__img {
      padding: 30px 35px;
      margin-bottom: 25px;

      h2 {
        font-size: 22px;
      }
    }

    &__table {
      table {
        tbody {
          tr {
            th {
              font-size: 16px;
            }

            td {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .contacts {

    background: $gray;

    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &:after {
      display: none;
    }

    &__img {
      display: none;
    }

    &-address {
      padding: 35px 0;

      h3 {
        font-size: 20px;
      }

      &__icon {
        &-item {
          margin-right: 5px;
        }
      }
    }
  }

  .policy {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  .license {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__foto {
      a {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  .https404 {
    &__title {
      font-size: 100px;
      line-height: 100px;
    }

    &__nm {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .reception {
    &__inner {
      h1 {
        font-size: 28px;
      }
    }
  }

  .operation {
    &-holder {
      padding: 35px 0;

      h2 {
        font-size: 20px;
      }
    }

    &-price {
        &__nm {
          font-size: 20px;

        }
    }

    &-text {
      &__inner {
        display: block;
      }

      &__tx {
        padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }

      &__pl {
        max-width: 100%;
        margin: 60px 0;

        position: relative;
      }

      &__consultation {
        padding: 20px 0;

        text-align: center;

        &:after {
          height: 230px;
          width: 100%;

          right: 0;
        }
      }
    }

    &__text {
      flex: 0 0 100%;
      max-width: 100%;

      h1 {
        font-size: 24px;
      }
    }

    &__inner {
      height: auto;
      padding-bottom: 250px;
      display: flex;
    }

    &__img {
      width: 370px;
      height: 292px;
      margin-left: -185px;
    }

    &__list {
      display: block;

      &-item {
        flex: 0 0 100%;
        max-width: 100%;

        br {
          display: none;
        }
      }
    }
  }
}

@media screen and(max-width: 575px) {
  .header {
    &__phone {
      display: none;
    }
  }

  .banner {
    padding-top: 155px;
    padding-bottom: 80px;
    background-position: -272px 40px;
    background-size: auto 428px;

    .button {
      display: block;
      width: 200px;
      text-align: center;
      padding: 15px 25px;
      position: relative;
      margin-left: -44px;
      border-radius: 36px;
    }

    &__tx {
      font-size: 13px;
    }
  }

  .services {
    padding: 25px 0;

    &__hld {
      max-width: 100%;
      flex: 0 0 100%;
    }

    &__item {
      margin-bottom: 15px;
    }
  }

  .about {
    &__text {
      padding: 25px 0 20px;
    }

    &__descr {
      display: block;
      margin: 0;

      &-item {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
      }
    }

    &__doctor {
      display: block;
      margin-bottom: 25px;

      &-img {
        margin-right: 0;
        margin-bottom: 20px;

        img {
          width: 290px;
        }
      }
    }

    &__link {
      margin-top: 5px;
    }

    &__info {
      &-img {
        display: flex;

        a {
          height: 184px;
        }
      }
    }

    &-page {
      &__holder {
        display: block;
      }

      &__info {
        &-item {
          padding-right: 0;

          p {
            margin-bottom: 18px!important;
          }
        }
      }

      &__text {
        p {
          margin-bottom: 35px;
        }
      }
    }
  }

  .results {
    h2 {
      text-align: center;
    }
  }

  .reviews {
    &__body {
      margin-left: 0;

      &:after {
        right: 12px;
        top: -15px;
        left: auto;
      }
    }

    &__slider {
      .slick-slide {
        padding: 15px 0 0;
      }
    }

    &__item {
      padding: 0;
    }

    &__bottom {
      display: block;

      text-align: center;

      .button-group {
        margin-bottom: 20px;
      }
    }

    &-tw {
      padding: 35px 0;

      &__title {
        display: block;

        text-align: center;

        &-bl {
          .reviews__bottom-link,
          .button {
            display: block;
            margin: 5px auto 15px;
          }

          .button {
            width: 180px;
          }

          .reviews__body {
            padding: 35px 25px;
          }
        }
      }
    }

  }

  .catalog {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__item {
      width: 100%;
      flex: 0 0 100%;

      width: 100%;
      max-width: 340px;
      margin: 0 auto 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__img {
      width: 100%;
      height: 270px;
      max-width: 340px;
    }
  }

  .price {
    &__img {
      padding: 25px 20px;
      margin-bottom: 25px;

      h2 {
        font-size: 16px;
      }
    }

    &__table {
      table {
        tbody {
          tr {
            th {
              padding: 10px 5px 5px 5px;
              font-size: 14px;
            }

            td {
              padding: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .contacts {
    &-address {
      padding: 25px 0;

      &__inner {
        display: block;
      }

      &__item {
        padding-right: 0;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .reception {
    &__inner {
      width: 100%;
      padding-top: 20px;
    }

    &__list {
      width: 100%;
      margin-right: 0;
    }
  }

  .operation {
    &__text {
      p {
        margin-bottom: 20px;
      }

      h1 {
        font-size: 24px;
      }
    }

    &__inner {
      padding-bottom: 180px;
    }

    &__img {
      width: 280px;
      height: 221px;
      margin-left: -140px;
    }

    &__list {
      &-item {
        margin-bottom: 15px;
      }
    }
  }

  .modal-dialog {
    bottom: 60px;
  }
}